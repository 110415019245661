@font-face {
  font-family: "CircularStdRegular";
  src: url("../Fonts/CircularStdRegular.eot");
  src: local("☺"), url("../Fonts/CircularStdRegular.woff") format("woff"),
    url("../Fonts/CircularStdRegular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd-Book";
  src: url("../Fonts/CircularStd-Book.eot");
  src: local("☺"), url("../Fonts/CircularStd-Book.woff") format("woff"),
    url("../Fonts/CircularStd-Book.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GE-SS-Bold";
  src: url("../Fonts/ge ss two bold.eot");
  src: local("☺"), url("../Fonts/ge ss two bold.woff") format("woff"),
    url("../Fonts/ge ss two bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GE-SS-Light";
  src: url("../Fonts/ge ss two light.eot");
  src: local("☺"), url("../Fonts/ge ss two light.woff") format("woff"),
    url("../Fonts/ge ss two light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.Polaris-ButtonGroup {
  justify-content: center;
  flex-wrap: nowrap;
}
.Polaris-DataTable__Cell .Polaris-ButtonGroup {
  float: right;
}
.spinnerContainer {
  height: 200px;
  width: 100%;
  position: relative;
  display: table;
}

.vertical-center {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.parent {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
}
.parent > .child {
  display: table-cell;
  vertical-align: middle;
}
/* .Polaris-TopBar {
  background-color: #ff0054 !important;
} */
/* .Polaris-TopBar-UserMenu__Name {
  color: white;
} */
.Polaris-Modal-CloseButton {
  display: none;
}
.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 50px;
  z-index: 1;
}
.Polaris-DropZone--sizeExtraLarge {
  min-height: 8rem;
  max-height: 8rem;
}
.MuiPaginationItem-sizeLarge {
  font-size: 0.8rem !important;
}
.MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  font-size: 1.7rem !important;
}
.MuiPagination-ul {
  display: inline-flex !important;
}
.Polaris-Link {
  color: gray;
}
.gridItem:hover {
  cursor: pointer;
  background-color: #f9fafb;
}

.gridItem .bgd {
  background-color: grey;
  width: 50px;
  height: 50px;
}

.gridItem:hover .bgd {
  opacity: 0.7;
}
.gridItem:hover .iconDiv {
  background-color: #dfe3e8;
}
.description {
  color: grey;
  margin-top: 5px;
  font-size: 10;
}
.titleDev {
  color: #006fbb;
  margin-left: 10px;
}
.iconDiv {
  background-color: #f4f6f8;
  width: 40px;
  height: 40px;
  justify-content: center;
  border-radius: 2px;
  text-align: center;
  display: flex;
  padding: 10px 0;
}
.gridIcon {
  width: 20px;
  height: 20px;
}
.rowClick {
  cursor: pointer;
}
.rowDiv {
  display: flex;
  flex-direction: row;
}
/* .Polaris-DropZone-FileUpload__Action {
  display: none;
} */
/* .Polaris-DropZone--sizeLarge {
  min-height: 5rem !important;
} */
/* .Polaris-IndexTable-Checkbox--expanded {
  display: none !important;
}
.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
  display: none !important;
}
.Polaris-TopBar-UserMenu__Details {
  color: white;
} */
/* tbody .Polaris-DataTable__Cell {
  padding: 0px !important;
}
.row_click {
  cursor: pointer;
  padding: var(--p-space-4);
} */
.Polaris-DropZone-FileUpload__Action {
  width: 80px !important;
  height: 15px !important;

  /* display: none !important; */
}
.Polaris-DropZone-FileUpload__Action::after {
  content: "Add File" !important;

  /* display: none !important; */
}
.react-input-emoji--container {
  margin: 0px !important;
}
.react-input-emoji--container {
  /* min-height: 200px !important; */
}
.Polaris-DropZone-FileUpload__Action {
  background-color: transparent !important;
}
.react-input-emoji--container {
  border-radius: 0px !important;
  border-color: #dcdada !important;
}
.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg,
.Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg,
.Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused
  .Polaris-Navigation__Icon
  svg {
  fill: #ff0054 !important;
}
.Polaris-Navigation__Item--selected {
  background-color: #f8f0f3 !important;
  color: black !important;
}
.Polaris-Button--primary {
  background-color: #ff0054 !important;
}
/* .Polaris-ButtonGroup--segmented {
  float: left !important;
} */
.Polaris-Button.Polaris-Button--pressed {
  background: #ff0054 !important;
  border-color: #ff0054 !important;
}
/* .Polaris-ButtonGroup{
  float: left !important;
} */
.Polaris-Navigation__Item--selected::before {
  background-color: #ff0054 !important;
}
/* .Polaris-Navigation__Text {
  font-family: "CircularStd-Book" !important;
} */
/* .Polaris-Header-Title {
  font-family: "CircularStdRegular" !important;
} */
/* .Polaris-DataTable__Cell {
  font-family: "CircularStdRegular" !important;
}
.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--header {
  font-family: "CircularStd-Book" !important;
} */
/* body {
  overflow: hidden;
} */
/* html {
  margin: 0 auto;
  max-width: 1200px;
} */
[lang="ar"] .Polaris-Navigation__Text {
  text-align: right;
  padding-inline-start: 0.5rem;
  font-family: "GE-SS-Light";
}
[lang="ar"] .Polaris-Navigation__SectionHeading > :first-child {
  margin-right: 1.2rem;
}
[lang="ar"] .Polaris-DataTable__Cell .Polaris-ButtonGroup {
  float: left;
}
/* [lang="ar"] html,
body {
  max-width: 100%;
  overflow-x: hidden;
} */
[lang="ar"] .Polaris-Page-Header__RightAlign {
  margin-left: 0px;
}
[lang="ar"] .Polaris-Page-Header__PrimaryActionWrapper {
  margin-left: 0px;
}
[lang="ar"]
  .Polaris-Filters-ConnectedFilterControl__RightContainerWithoutMoreFilters
  .Polaris-Filters-ConnectedFilterControl__Item:last-child
  > div
  > button {
  margin-right: 0.7rem;
}
[lang="ar"]
  .Polaris-Filters-ConnectedFilterControl__CenterContainer
  + .Polaris-Filters-ConnectedFilterControl__RightContainer,
.Polaris-Filters-ConnectedFilterControl__CenterContainer
  + .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  margin-left: 0px;
}
[lang="ar"] .Polaris-Button__Icon {
  margin-left: 0.3rem;
  margin-right: 0.4rem !important;
}
[lang="ar"] .Polaris-DropZone-FileUpload__Action::after {
  content: "اضف ملف" !important;

  /* display: none !important; */
}
[lang="ar"] .Polaris-Header-Title {
  padding-right: 0.7rem;
  font-family: "GE-SS-Light";
}
[lang="ar"] .Polaris-Breadcrumbs__Breadcrumb {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
[lang="ar"] .Polaris-Page-Header__BreadcrumbWrapper {
  margin-right: 0px;
}
[lang="ar"] .Polaris-ActionList__Prefix {
  margin-left: 10px;
  white-space: nowrap;
}
/* [lang="ar"] body {
  overflow-x: hidden;
} */

[lang="ar"] .Polaris-Frame--hasNav .Polaris-Frame__Main {
  padding-left: 0;
  padding-right: calc(15rem + env(safe-area-inset-left));
}
[lang="ar"] .Polaris-Frame__Navigation {
  left: auto;
  right: 0;
  z-index: 0;
}
[lang="ar"] .Polaris-Navigation {
  border-right: 0;
  border-left: var(--p-border-divider);
}
[lang="ar"] .Polaris-Navigation__Item--selected::before {
  left: auto;
  right: calc(var(--p-space-2) * -1);
}
[lang="ar"]
  .Polaris-DataTable__StickyHeaderEnabled
  .Polaris-DataTable__StickyHeaderInner:not(
    .Polaris-DataTable__StickyHeaderInner--isSticky
  ) {
  left: auto !important;
  right: 0 !important;
}
[lang="ar"] .MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  transform: scale(-1);
}
@media (min-width: 768px) {
  [lang="ar"] .Polaris-Frame__Navigation {
    z-index: 0;
  }
}

[lang="ar"]
  .Polaris-DataTable__IncreasedTableDensity
  .Polaris-DataTable__Cell--header {
  text-align: right;
}

[lang="ar"] .Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell {
  padding: var(--p-space-2) var(--p-space-4);
  text-align: right;
  direction: ltr;
}
[lang="ar"] .Polaris-Button__Text {
  font-family: "GE-SS-Light";
}
/* [lang="ar"] .Polaris-FormLayout__Item {
  font-family: "GE-SS-Light";
}
[lang="ar"] .Polaris-Button__Text {
  font-family: "GE-SS-Light";
} */
[lang="en"]
  .Polaris-DataTable__IncreasedTableDensity
  .Polaris-DataTable__Cell--header,
.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__RowCountIsEven
  .Polaris-DataTable__TableRow:nth-child(2n + 1) {
  text-align: left;
}

[lang="ar"] .Polaris-ActionList__Item {
  text-align: right;
}
[lang="ar"] .dashboardBadge {
  float: left;
}
[lang="en"] .dashboardBadge {
  float: right;
}
[lang="ar"] .Polaris-Choice__Label {
  margin-right: 0.3rem;
}
[lang="ar"] .Polaris-InlineError__Icon {
  margin-left: 0;
}
